import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.scss';

const LoadingIndicator = ({ pageLoading }) => {
  return (
    <div className={classnames('loader-component', { hide: !pageLoading })}>
      <div className="cssload-dots">
        <div className="cssload-dot" />
        <div className="cssload-dot" />
        <div className="cssload-dot" />
        <div className="cssload-dot" />
        <div className="cssload-dot" />
      </div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              result="blur"
              stdDeviation="12"
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0	0 1 0 0 0	0 0 1 0 0	0 0 0 18 -7"
              result="goo"
            ></feColorMatrix>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

LoadingIndicator.propTypes = {
  pageLoading: PropTypes.boolean,
};

export default LoadingIndicator;

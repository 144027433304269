import newmark1 from './img/newmark-1.png';
import newmark2 from './img/newmark-2.png';
import ifit1 from './img/ifit-1.png';
import ifit2 from './img/ifit-2.png';
import ifit3 from './img/ifit-3.png';
import ifit4 from './img/ifit-4.png';

export default [
  {
    product: 'Commercial Real estate website',
    company: 'Newmark Grubb',
    subtitle: 'Easy to use search tool for commercial properties',
    description:
      'We designed and developed a site to allow commercial real estate customers to view properties as they encountered them. We leveraged the Wordpress backend to allow Newmark to administer their own property search page and functionality. Includes an overall property map, individual property information, document, and virtual tours.',
    images: [
      {
        src: newmark1,
        alt: 'Newmark homepage',
      },
      {
        src: newmark2,
        alt: 'Newmark property page',
      },
    ],
  },
  {
    product: 'Fitness Frenzy Endless Runner',
    company: 'iFit',
    subtitle: 'Fun and simple endless runner game',
    description:
      "We developed a fun, retro endless runner as part of iFit's Cyber Monday promotion to get users engaged with the brand and talking. It worked well on both desktop browsers and mobile devices.",
    images: [
      {
        src: ifit1,
        alt: 'iFit runner',
      },
      {
        src: ifit2,
        alt: 'iFit runner',
      },
      {
        src: ifit3,
        alt: 'iFit runner',
      },
      {
        src: ifit4,
        alt: 'iFit runner',
      },
    ],
  },
];

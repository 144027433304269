import React from 'react';
import './services.scss';

const Services = () => {
  return (
    <section className="services-component">
      <div className="left-column">
        <h1>Get in touch to chat about your next project!</h1>
        <div className="left-column-services">
          <p>Or see if any of our many services fit your needs:</p>
          <ul>
            <li>Web Applications (Design, Database, UI/UX)</li>
            <li>Mobile Applications (iOS, Android)</li>
            <li>Desktop Applications (Design, UI/UX)</li>
            <li>Mobile Games</li>
            <li>Site Maintenance</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import workData from './work-data';
import './work.scss';

const WorkSection = ({ pageLoading }) => {
  return (
    <section className={classnames('work-component', { loaded: !pageLoading })}>
      <div className="right-aligned-work">
        <h2>Featured Work</h2>
        {workData.map((work) => (
          <article key={work.product} className="work-article">
            <hr className="work-article-divider" />
            <div className="work-copy">
              <h3>{work.company}</h3>
              <h4>{work.product}</h4>
              <p className="work-subtitle">{work.subtitle}</p>
              <hr className="work-article-mini-divider" />
              <div
                className={classnames('work-slider', {
                  landscape: !work.portrait,
                })}
              >
                {work.images.map((image) => (
                  <img key={image.src} src={image.src} alt={image.alt} />
                ))}
              </div>
              <p className="work-desciption">{work.description}</p>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

WorkSection.propTypes = {
  pageLoading: PropTypes.boolean,
};

export default WorkSection;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './hero.scss';

const Hero = ({ pageLoading }) => {
  return (
    <section className={classnames('hero-component', { loaded: !pageLoading })}>
      <div className="left-column">
        <h1>
          <span className="first-letter">B</span>uilding beautiful and
          functional web and mobile applications.
        </h1>
      </div>
      <div className="right-column">
        <div>
          <p>
            <strong>Mostly Ghosts</strong> delivers brilliant user experiences
            through beautiful websites, mobile applications, and games. We shape
            digital products that hum from design to front to back (end) and
            that, frankly, exist on their own ethereal&nbsp;plane.
          </p>
        </div>
      </div>
    </section>
  );
};

Hero.defaultProps = {
  pageLoading: false,
};

Hero.propTypes = {
  pageLoading: PropTypes.boolean,
};

export default Hero;

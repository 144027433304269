import React, { Component } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../page-content/homepage/hero';
import Services from '../page-content/homepage/services';
import Work from '../page-content/homepage/work';
import Loader from '../components/loader';

class IndexPage extends Component {
  state = { pageLoading: true };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ pageLoading: false });
    }, 500);
  }

  render() {
    const { pageLoading } = this.state;

    return (
      <Layout>
        <SEO title="Home" />
        <Hero />
        <Work />
        <Services />
        <Loader pageLoading={pageLoading} />
      </Layout>
    );
  }
}

export default IndexPage;
